.print-agreement {
  margin: 5px;
  /* font-size: smaller; */
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
  button {
    visibility: hidden;
  }
}

@media screen {
  .header {
    display: none;
  }
}
